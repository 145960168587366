import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ISelectDropdown } from 'src/app/@Core/interface/select-dropdown.interface';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { ActivatedRoute, Router } from '@angular/router';


import { AgentService } from '../../agent/service/agent.service';
import { CommonModule } from '@angular/common';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { UserService } from '../../user/service/user.service';
import { NotificationService } from 'src/app/@Core/services/notification.service';
import { MustMatch } from 'src/app/@Core/validator/match.validator';
import { NzIconModule } from 'ng-zorro-antd/icon';

@Component({
    standalone: true,
    imports:[CommonModule,
      NzFormModule,
      NzInputModule,
      NzButtonModule,
      NzIconModule,
      NzCardModule,
      NzCheckboxModule,
      ReactiveFormsModule
    ],
    selector: 'app-confirm-email',
    templateUrl: './confirm-email.component.html',
})

export class ConfirmEmailComponent implements OnInit {
  loginForm: UntypedFormGroup;
  routeId: string = "";
  passwordVisible:boolean = false;
  passwordVisible1:boolean = false;
  constructor(
    private fb: UntypedFormBuilder,
    private notify: NotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private service: UserService,
  ) {
    this.loginForm = this.fb.group({
      password: [ null, [ Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[^\\w\\s])[A-Za-z\\d\\W]{8,}$')] ],
      rePassword: [ null, [ Validators.required ] ],
  },{
    validator: MustMatch('password', 'rePassword')
});
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.routeId = params['tokenId'];
      this.service.verifyPasswordToken(this.routeId)
      .subscribe((data)=>{

      }, (error)=>{
        // this.router.navigate(['/auth/login']);
      })
    });
   
  }
  
  submitForm(): void {
    for (const i in this.loginForm.controls) {
        this.loginForm.controls[ i ].markAsDirty();
        this.loginForm.controls[ i ].updateValueAndValidity();
    }
    this.service.updatePassword(this.loginForm.value).subscribe(
        data=>{
            if(data){
                this.router.navigate(['/dashboard/home'])
            }
        }, error=>{
            console.log(error);
            this.notify.errorNotify("Error", error.error.message);
        }
    )
}

 
 
}
