import {HttpClient, HttpHeaders} from '@angular/common/http';

import {catchError, map} from 'rxjs/operators';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {Router} from '@angular/router';
import { configApiUrl } from 'src/app/app-const';
import { jwtDecode } from "jwt-decode";


export class GenericService<T> {
    protected http: HttpClient;
    protected baseUrl: string;
    protected router: Router;
    static status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


    public constructor(http: HttpClient, baseUrl: string, router: Router) {
        this.http = http;
        this.baseUrl = baseUrl;
        this.router = router;
    }

    public save(data: T): Observable<T> {
        return this.http.post(this.baseUrl, data, {observe: 'response'}).pipe(
            map((res: any) => {
                this.setUpdatedHeader(res);
                return res.body;
            }),
            catchError((error) => this.handleError(error))
        );
    }
    public postFormData(data: any): Observable<any> {
        const formData = new FormData();
    const appendFormData = (fd: FormData, data: any, rootName: string = '') => {
        if (data instanceof File) {
            fd.append(rootName, data);
        } else if (Array.isArray(data)) {
            data.forEach((item, index) => {
                appendFormData(fd, item, `${rootName}[${index}]`);
            });
        } else if (typeof data === 'object' && data !== null) {
            Object.keys(data).forEach((key) => {
                const propName = rootName ? `${rootName}.${key}` : key;
                appendFormData(fd, data[key], propName);
            });
        } else {
            fd.append(rootName, data);
        }
    };

    Object.keys(data).forEach((key) => {
        appendFormData(formData, data[key], key);
    });
        return this.http.post(this.baseUrl, formData, {observe: 'response'}).pipe(
            map((res: any) => {
                this.setUpdatedHeader(res);
                return res.body;
            }),
            catchError((error) => this.handleError(error))
        );
    }

    public getById(id: string): Observable<T> {
        return this.http.get(this.baseUrl + '/' + id, {observe: 'response'}).pipe(
            map((res: any) => {
                this.setUpdatedHeader(res);
                return res.body;
            }),
            catchError((error) => this.handleError(error))
        );
    }

   

    public getSearchList(data): Observable<T> {
        return this.http.post(this.baseUrl + '/search', data, {observe: 'response'}).pipe(
            map((res: any) => {
                this.setUpdatedHeader(res);
                return res.body;
            }),
            catchError((error) => this.handleError(error))
        );
    }

    public getSearchField(): Observable<T> {
        return this.http.get(this.baseUrl + '/searchFields', {observe: 'response'}).pipe(
            map((res: any) => {
                this.setUpdatedHeader(res);
                return res.body;
            }),
            catchError((error) => this.handleError(error))
        );
    }

    public update(id: string, data: T): Observable<T> {
        let updateUrl = id !== '' ? this.baseUrl + '/' + id : this.baseUrl;
        return this.http.put(updateUrl, data, {observe: 'response'}).pipe(
            map((res: any) => {
                this.setUpdatedHeader(res);
                return res.body;
            }),
            catchError((error) => this.handleError(error))
        );
    }
    public updateFormData(id: string, data: any): Observable<T> {
        const formData = new FormData();
        const appendFormData = (fd: FormData, data: any, rootName: string = '') => {
            if (data instanceof File) {
                fd.append(rootName, data);
            } else if (Array.isArray(data)) {
                data.forEach((item, index) => {
                    appendFormData(fd, item, `${rootName}[${index}]`);
                });
            } else if (typeof data === 'object' && data !== null) {
                Object.keys(data).forEach((key) => {
                    const propName = rootName ? `${rootName}.${key}` : key;
                    appendFormData(fd, data[key], propName);
                });
            } else {
                fd.append(rootName, data);
            }
        };
    
        Object.keys(data).forEach((key) => {
            appendFormData(formData, data[key], key);
        });
        return this.http.put(this.baseUrl + '/' + id, formData, {observe: 'response'}).pipe(
            map((res: any) => {
                this.setUpdatedHeader(res);
                return res.body;
            }),
            catchError((error) => this.handleError(error))
        );
    }

   
    public delete(id: number): Observable<T> {
        return this.http.delete(this.baseUrl + 'delete/' + id, {observe: 'response'}).pipe(
            map((res: any) => {
                this.setUpdatedHeader(res);
                return res.body;
            }),
            catchError((error) => this.handleError(error))
        );
    }
    public getDocumentById(id: number): Observable<T> {
        return this.http.get(configApiUrl.DOCUMENT_API  + id, { responseType: 'blob' }).pipe(
            map((res: any) => {
                return res;
            }),
            catchError((error) => this.handleError(error))
        );
    }

    public getHttpHeaders(): HttpHeaders {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.set('Accept', 'application/json');
        return headers;
    }

    setUpdatedHeader(res) {
        localStorage.setItem('token', JSON.stringify(res.headers.get('Authorization')));
    }


    public display(value: boolean) {
        GenericService.status.next(value);
    }

    formattedDate(date): string {
        if (date) {
            const datePart = date.day;
            const monthPart = date.month;
            const yearPart = date.year;

            return (yearPart) + '-' + (monthPart < 10 ? '0' + monthPart : monthPart) + '-' + (datePart < 10 ? '0' + datePart : datePart);

        }
    }

    protected handleError(error: any) {
        if (error.status == 401) {
            this.router.navigateByUrl('/auth/login').then(r => this.display(false));
        } else if (error.status == 403) {
            this.router.navigateByUrl('/accessDenied').then(r => this.display(false));
        } else {
            return throwError(error);
        }
    }
    createUniqueObjects(fields: { field: string, value: any }[]): { field: string, value: any }[] {
        const uniqueObjects: { field: string, value: any }[] = [];
      
        fields.forEach(field => {
          if (field.value) {
            uniqueObjects.push({ field: field.field, value: field.value });
          }
        });
        return uniqueObjects;
      }
    convertBlobToBase64(blob: Blob): Promise<string> {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            resolve(reader.result as string);
          };
          reader.onerror = error => {
            reject(error);
          };
        });
      }

    blobToFile(theBlob, fileName){       
        return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
    }

}