import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { RouterModule } from "@angular/router";
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { ThemeConstantService } from './services/theme-constant.service';
import { SearchPipe } from './pipes/search.pipe';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { SanitizeSafeUrlPipe } from './pipes/sanitize-safe-url.pipe';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { TrimDirective } from './directives/trim.directive';
import { NumberInputDirective } from './directives/number.directive';
import {TimeFormatPipe} from "./pipes/time-format.pipe";
import { ReplaceUnderscorePipe } from './pipes/replace-underscore.pipe';

@NgModule({
    declarations: [
        SearchPipe,
        SanitizeSafeUrlPipe,
        TrimDirective,
        NumberInputDirective,
        TimeFormatPipe,
        ReplaceUnderscorePipe
    ],
    imports: [
        RouterModule,
        CommonModule,
        ReactiveFormsModule,
        NzIconModule,
        NzToolTipModule,
        NzCardModule,
        NzButtonModule,
        NzTableModule,
        NzInputModule,
        NzFormModule,
        NzModalModule,
        NzSelectModule,
        NzDropDownModule,
        NzCollapseModule,
        NzAvatarModule,
        NzSpinModule,
        NgxMaskDirective,
        NgxMaskPipe
    ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NzIconModule,
    SearchPipe,
    NzCardModule,
    NzButtonModule,
    NzTableModule,
    NzInputModule,
    NzFormModule,
    NzToolTipModule,
    NzModalModule,
    NzSelectModule,
    NzDropDownModule,
    NzCollapseModule,
    NzAvatarModule,
    SanitizeSafeUrlPipe,
    NzSpinModule,
    NgxMaskDirective,
    NgxMaskPipe,
    TrimDirective,
    NumberInputDirective,
    TimeFormatPipe,
    ReplaceUnderscorePipe
  ],
    providers: [
        ThemeConstantService
    ]
})

export class SharedModule { }
