import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {GenericService} from 'src/app/@Core/services/generic.service';
import {configApiUrl} from 'src/app/app-const';

@Injectable({
  providedIn: 'root',
})

export class BookingService extends GenericService<any> {
  private selectedDate: any;

  setSelectedDate(selectedDate: any){
    this.selectedDate = selectedDate;
  }

  getSelectedDate(): any{
    return this.selectedDate;
  }

  constructor(http: HttpClient, router: Router) {
    super(http, configApiUrl.BOOKING_API, router);
  }

  private currentListSubject = new BehaviorSubject<string | null>(null);
  currentList$ = this.currentListSubject.asObservable();

  setCurrentList(obj: string): void {
    this.currentListSubject.next(obj);
  }

  getBookingCalendar(params: any): Observable<any> {
    return this.http.post(this.baseUrl + '/calender', params, {observe: 'response'}).pipe(
        map((res: any) => {
          this.setUpdatedHeader(res);
          return res.body;
        }),
        catchError((error) => this.handleError(error))
    );
  }

  getBookingCalendarDetail(params: any): Observable<any> {
    return this.http.post(this.baseUrl + '/calender/detail', params, {observe: 'response'}).pipe(
        map((res: any) => {
          this.setUpdatedHeader(res);
          return res.body;
        }),
        catchError((error) => this.handleError(error))
    );
  }

  getPriceByCountry(country, serviceCode): Observable<any> {
    return this.http.get(this.baseUrl + '/price/' + country + '/' + serviceCode, {observe: 'response'}).pipe(
        map((res: any) => {
          this.setUpdatedHeader(res);
          return res.body;
        }),
        catchError((error) => this.handleError(error))
    );
  }

  getScheduleDetailList(params: any): Observable<any> {
    return this.http.post(this.baseUrl + '/bookingInfo', params, {observe: 'response'}).pipe(
        map((res: any) => {
          this.setUpdatedHeader(res);
          return res.body;
        }),
        catchError((error) => this.handleError(error))
    );
  }

  getBookingDetail(id: any): Observable<any> {
    return this.http.get(this.baseUrl + '/bookingDetail/' + id, {observe: 'response'}).pipe(
        map((res: any) => {
          this.setUpdatedHeader(res);
          return res.body;
        }),
        catchError((error) => this.handleError(error))
    );
  }

  cancelBooking(params: any, id: any): Observable<any> {
    return this.http.put(this.baseUrl + '/cancelBooking/' + id, params, {observe: 'response'}).pipe(
        map((res: any) => {
          this.setUpdatedHeader(res);
          return res.body;
        }),
        catchError((error) => this.handleError(error))
    );
  }

  cancelBookingDetail(params: any, id: any): Observable<any> {
    return this.http.put(this.baseUrl + '/cancelBookingDetail/' + id, params, {observe: 'response'}).pipe(
        map((res: any) => {
          this.setUpdatedHeader(res);
          return res.body;
        }),
        catchError((error) => this.handleError(error))
    );
  }

  getRefundDetail(id: any): Observable<any> {
    return this.http.get(this.baseUrl + '/refundDetail/' + id, {observe: 'response'}).pipe(
        map((res: any) => {
          this.setUpdatedHeader(res);
          return res.body;
        }),
        catchError((error) => this.handleError(error))
    );
  }

  getRefund(id: any): Observable<any> {
    return this.http.put(this.baseUrl + '/refund/' + id, {}, {observe: 'response'}).pipe(
        map((res: any) => {
          this.setUpdatedHeader(res);
          return res.body;
        }),
        catchError((error) => this.handleError(error))
    );
  }

  getPaymentDetail(id: any): Observable<any> {
    return this.http.get(this.baseUrl + '/paymentDetail/' + id, {observe: 'response'}).pipe(
        map((res: any) => {
          this.setUpdatedHeader(res);
          return res.body;
        }),
        catchError((error) => this.handleError(error))
    );
  }

  getMarkAsPaid(id: any): Observable<any> {
    return this.http.put(this.baseUrl + '/markAsPaid/' + id, {}, {observe: 'response'}).pipe(
        map((res: any) => {
          this.setUpdatedHeader(res);
          return res.body;
        }),
        catchError((error) => this.handleError(error))
    );
  }

  getUpcomingFlight(): Observable<any> {
    return this.http.get(this.baseUrl + '/upcomingFlight', {observe: 'response'}).pipe(
        map((res: any) => {
          this.setUpdatedHeader(res);
          return res.body;
        }),
        catchError((error) => this.handleError(error))
    );
  }

  getTodaysFlight(): Observable<any> {
    return this.http.get(this.baseUrl + '/todaysFlight', {observe: 'response'}).pipe(
        map((res: any) => {
          this.setUpdatedHeader(res);
          return res.body;
        }),
        catchError((error) => this.handleError(error))
    );
  }

}
