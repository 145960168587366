import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {NZ_MODAL_DATA, NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {NzMessageService} from 'ng-zorro-antd/message';
import {BookingService} from '../service/booking.service';
import {UserService} from '../../user/service/user.service';
import {jwtDecode} from 'jwt-decode';
import {
  DetailDialogComponent
} from '../dialog-schedule-detail/detail-dialog/detail-dialog.component';
import {
  ConfirmBookingComponent
} from '../dialog-schedule-detail/confirm-booking/confirm-booking.component';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {
  RefundDetailDialogComponent
} from '../dialog-schedule-detail/refund-detail-dialog/refund-detail-dialog.component';

@Component({
  selector: 'app-view-booking',
  templateUrl: './view-booking.component.html',
  styleUrls: ['./view-booking.component.css']
})
export class ViewBookingComponent {
  @Output() actionOccurred = new EventEmitter<boolean>();
  image: any = '';
  tokenDetail: any = jwtDecode(localStorage.getItem('token'));
  paymentAuthorizedBy: string;
  discountAuthorizedBy: string;
  cancelObj: any;


  constructor(
      private modal: NzModalRef,
      @Inject(NZ_MODAL_DATA) public data: any,
      private message: NzMessageService,
      private service: BookingService,
      private userService: UserService,
      public modalService: NzModalService,
      private notification: NzNotificationService,

  ) {
    if (this.data?.bookingData?.customerDetail?.registrationDocumentId) {
      this.service.getDocumentById(this.data?.bookingData?.customerDetail?.registrationDocumentId)
      .subscribe((resImg) => {
        this.service.convertBlobToBase64(resImg).then(base64 => {
          this.image = base64;
        });
      });
    }
    if (this.data?.bookingData?.paymentDetail?.discountAuthorizedBy){
      this.userService.getById(this.data?.bookingData?.paymentDetail?.discountAuthorizedBy)
      .subscribe((user) =>
      {
        const firstName = user?.data?.firstName;
        const lastName = user?.data?.lastName;
        this.discountAuthorizedBy = firstName + ' ' + lastName;
      });
    }
    if (this.data?.bookingData?.paymentDetail?.paymentAuthorizedBy){
      this.userService.getById(this.data?.bookingData?.paymentDetail?.paymentAuthorizedBy)
      .subscribe((user) =>
      {
        const firstName = user?.data?.firstName;
        const lastName = user?.data?.lastName;
        this.paymentAuthorizedBy = firstName + ' ' + lastName;
      });
    }
  }

  getBookingStatusTagColor(status: string): string {
    switch (status) {
      case 'BOOKED':
        return 'green';
      default:
        return 'red';
    }
  }

  getPaymentStatusTagColor(status: string): string{
    switch (status) {
      case 'PAID':
        return 'green';
      case 'UNPAID':
        return 'red';
      default:
        return 'orange';
    }
  }

  destroyModal(): void {
    this.modal.destroy();
  }

  cancelBooking(type: string, id: any = null): void {
    const modalRef: NzModalRef = this.modalService.create({
      nzTitle: 'Schedule Detail',
      nzWidth: '500px',
      nzData: {
        type: 'single',
        selectedSchedule: 0
      },
      nzContent: DetailDialogComponent
    });

    modalRef.afterClose.subscribe(result => {
      if (result) {
        this.cancelObj = result;
        this.cancelConfirm(type, id);
      }
    });
  }

  cancelConfirm(type: any, id: any): void {
    const modalRef: NzModalRef = this.modalService.confirm({
      nzTitle: 'Warning',
      nzWidth: '500px',
      nzData: {
        title: 'Are you Sure? You want to cancel Schedule'
      },
      nzContent: ConfirmBookingComponent,
      nzOnOk: (val: any) => {

        const params = {
          cancelType: this.cancelObj.cancelledBy,
          cancelReason: this.cancelObj.cancelReason,
        };

        this.service.cancelBookingDetail(params, id)
        .subscribe(
            (resp: any) => {
              if (resp?.data) {
                this.notification.success('success', resp.message);
                // this.getDetail();
                // parentModalRef.destroy(true);
                this.closeModelAndEmitEvent();
              }
            },
            err => {
              this.notification.error('error', err.error.message);
            }
        );
      },
      nzOnCancel: () => {
        console.log('Delete action canceled');
      }
    });
  }

  payDetail(id): void {
    this.service.getPaymentDetail(id)
    .subscribe(
        (resp: any) => {
          if (resp?.data) {
            const modalRef: NzModalRef = this.modalService.create({
              nzTitle: 'Payment Detail',
              nzWidth: '500px',
              nzData: {
                data: resp.data
              },
              nzContent: RefundDetailDialogComponent
            });

            modalRef.afterClose.subscribe(result => {
              // tslint:disable-next-line:triple-equals
              if (result == true) {
                this.refundConfirm(id, 'payment');
              }
            });
          }
        },
        err => {
          this.notification.error('error', err.error.message);
        }
    );
  }

  refundDetail(id): void {
    this.service.getRefundDetail(id)
    .subscribe(
        (resp: any) => {
          if (resp?.data) {
            const modalRef: NzModalRef = this.modalService.create({
              nzTitle: 'Refund',
              nzWidth: '500px',
              nzData: {
                data: resp.data
              },
              nzContent: RefundDetailDialogComponent
            });

            modalRef.afterClose.subscribe(result => {
              if (result == true) {
                this.refundConfirm(id);
              }
            });
          }
        },
        err => {
          this.notification.error('error', err.error.message);
        }
    );
  }

  refundConfirm(id: any, payment: any = null): void {
    let title =  'Are you Sure? You want to Refund';
    if (payment){
      title = 'Are you Sure? You want to mark as Paid';
    }
    const modalRef: NzModalRef = this.modalService.confirm({
      nzTitle: 'Warning',
      nzWidth: '500px',

      nzData: {
        title
      },
      nzContent: ConfirmBookingComponent,
      nzOnOk: (val: any) => {

        if (payment) {
          this.service.getMarkAsPaid(id)
          .subscribe(
              (resp: any) => {
                if (resp?.data) {
                  this.notification.success('success', resp.message);
                  this.closeModelAndEmitEvent();
                }
              },
              err => {
                this.notification.error('error', err.error.message);
              }
          );
        }

        else {

          this.service.getRefund(id)
          .subscribe(
              (resp: any) => {
                if (resp?.data) {
                  this.notification.success('success', resp.message);
                  this.closeModelAndEmitEvent();
                }
              },
              err => {
                this.notification.error('error', err.error.message);
              }
          );
        }

      },
      nzOnCancel: () => {
        console.log('Delete action canceled');
      }
    });
  }

  closeModelAndEmitEvent(): void{
    this.destroyModal();
    this.actionOccurred.emit(true);
  }
}
