<div class="ant-row">
  <div class="ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24">
    <nz-card class="ant-card ant-card-bordered" nzTitle="Flight Details">
      <div class="ant-row">
        <div class="ant-col-xs-24 ant-col-sm-24 ant-col-md-8 ant-col-lg-8">
          <div class="ant-descriptions-item-container">
            <span class="ant-descriptions-item-label font-weight-semibold "> Flight Date </span>
            <span>{{data?.bookingData?.bookingDate | date:'yyyy/MM/dd' }}</span>
          </div>
        </div>
        <div class="ant-col-xs-24 ant-col-sm-24 ant-col-md-8 ant-col-lg-8">
          <div class="ant-descriptions-item-container">
            <span class="ant-descriptions-item-label font-weight-semibold "> Flight Time </span>
            <span > {{data?.bookingData?.schedule?.startTime | timeFormat}}</span>
          </div>
        </div>
      </div>
      <br>
      <div class="ant-row">
        <div class="ant-col-xs-24 ant-col-sm-24 ant-col-md-8 ant-col-lg-8">
          <div class="ant-descriptions-item-container">
            <span class="ant-descriptions-item-label font-weight-semibold "> Schedule Name </span>
            <span > {{data?.bookingData?.schedule?.name}}</span>
          </div>
        </div>
        <div class="ant-col-xs-24 ant-col-sm-24 ant-col-md-8 ant-col-lg-8">
          <div class="ant-descriptions-item-container">
            <span class="ant-descriptions-item-label font-weight-semibold "> Balloon Name </span>
            <span > {{data?.bookingData?.category?.name}}</span>
          </div>
        </div>
      </div>
      <br>
      <div class="ant-row">
        <div class="ant-col-xs-24 ant-col-sm-24 ant-col-md-8 ant-col-lg-8">
          <div class="ant-descriptions-item-container">
            <span class="ant-descriptions-item-label font-weight-semibold "> Flight Status </span>
            <span > <nz-tag [nzColor]="getBookingStatusTagColor(data?.bookingData?.status)">{{data?.bookingData?.status | replaceUnderscore }}</nz-tag></span>
          </div>
        </div>
        <div class="ant-col-xs-24 ant-col-sm-24 16 ant-col-lg-16" *ngIf = "data?.bookingData?.cancelReason">
          <div class="ant-descriptions-item-container">
            <span class="ant-descriptions-item-label font-weight-semibold "> Reason </span>
            <span > {{data?.bookingData?.cancelReason}}</span>
          </div>
        </div>
      </div>
    </nz-card>
  </div>
</div>

<div class="ant-row" *ngIf="data.bookingData?.agent">
  <div class="ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24">
    <nz-card class="ant-card ant-card-bordered" nzTitle="Agent Details">
      <div class="ant-row">
        <div class="ant-col-xs-24 ant-col-sm-24 ant-col-md-8 ant-col-lg-8">
          <div class="ant-descriptions-item-container">
            <span class="ant-descriptions-item-label font-weight-semibold "> Agent name </span>
            <span>{{data.bookingData?.agent?.name}}</span>
          </div>
        </div>
        <div class="ant-col-xs-24 ant-col-sm-24 ant-col-md-8 ant-col-lg-8">
          <div class="ant-descriptions-item-container">
            <span class="ant-descriptions-item-label font-weight-semibold "> Contact Person</span>
            <span>{{data.bookingData?.agent?.contactPerson}}</span>
          </div>
        </div>
      </div>
      <br>
      <div class="ant-row">
        <div class="ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24">
          <div class="ant-descriptions-item-container">
            <span class="ant-descriptions-item-label font-weight-semibold "> Contact number </span>
            <span>{{data.bookingData?.agent?.primaryContactNumber}} </span>

            <span *ngIf="data.bookingData?.agent?.secondaryContactNumber"> /{{data.bookingData?.agent?.secondaryContactNumber}}</span>

          </div>
        </div>
      </div>
    </nz-card>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
    <nz-card class="ant-card ant-card-bordered" nzTitle="Customer Details">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 form-group">
          <nz-form-label>
            Full Name
          </nz-form-label>
          <br>
          <span>
            {{data.bookingData?.customerDetail?.fullName}}
          </span>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 form-group">
          <nz-form-label>
            Contact Number
          </nz-form-label>
          <br>
           <span>
            {{data.bookingData?.customerDetail?.contactNumber}}
          </span>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 form-group">
          <nz-form-label>
            Email
          </nz-form-label>
          <br>
            <span>{{data.bookingData?.customerDetail?.email}}</span>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 form-group">
          <nz-form-label>
            Weight
          </nz-form-label>
         <br>
              <span>{{data.bookingData?.customerDetail?.weight}}</span>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 form-group">
          <nz-form-label>
            Pickup Location
          </nz-form-label>
          <br>
              <span>{{data.bookingData?.customerDetail?.pickUpLocation}}</span>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 form-group">
          <nz-form-label>
            DOB
          </nz-form-label>
          <br>
              <span>{{data.bookingData?.customerDetail?.dateOfBirth}}</span>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 form-group">
          <nz-form-label>
            Nationality
          </nz-form-label>
          <br>
              <span>{{data.bookingData?.customerDetail?.country}}</span>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 form-group">
          <nz-form-label>
            Gender
          </nz-form-label>
          <br>
              <span>{{data.bookingData?.customerDetail?.gender}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 form-group">
          <div class="image-content">
            <div *ngIf="image !=='' "
                 class="form-group mt-2 mb-2 text-center">
              <img class="event-img" [src]="image" alt="event-image">
            </div>
          </div>
        </div>
      </div>
    </nz-card>
  </div>

  <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
    <nz-card class="ant-card ant-card-bordered" nzTitle="Payment Details">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-group">
          <nz-form-label>
            Payment status
          </nz-form-label>
          <br>
          <span>
            <nz-tag [nzColor]="getPaymentStatusTagColor(data.bookingData?.paymentDetail.paymentStatus)">{{data.bookingData?.paymentDetail.paymentStatus}}</nz-tag>
          </span>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-group"
             *ngIf="!data.bookingData?.agent && data.bookingData?.paymentDetail.paymentStatus == 'UNPAID'">
          <nz-form-label>
            Payment Authorized by
          </nz-form-label>
          <br>
          <span>
            {{paymentAuthorizedBy}}
          </span>
        </div>
      </div>
      <div class="row" *ngIf="tokenDetail.role!='AGENT' && data.bookingData?.paymentDetail.discountDetail?.discountType">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-group">
          <nz-form-label>
            Discount Type
          </nz-form-label>
          <br>
          <span>
            {{data.bookingData?.paymentDetail.discountDetail?.name}}
          </span>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-group">
          <nz-form-label>
            Discount Authorized by
          </nz-form-label>
          <br>
          <span>{{discountAuthorizedBy}}</span>
        </div>
      </div>

    </nz-card>
    <nz-card class="ant-card ant-card-bordered" nzTitle="Payment Summary">
      <div class="row" *ngIf="data.bookingData?.paymentDetail.exchangeRateDetail.currency =='DOLLAR'">
        <div class="col-7">
          Dollar Amount
        </div>
        <div class="col-5">
          $ {{this.data.bookingData?.paymentDetail.exchangeRateDetail.amount}}
        </div>
      </div>
      <div class="row" *ngIf="data.bookingData?.paymentDetail.exchangeRateDetail.currency =='DOLLAR'">
        <div class="col-7">
          Exchange Rate
        </div>
        <div class="col-5">
          Rs {{this.data.bookingData?.paymentDetail.exchangeRateDetail.rate}}
        </div>
      </div>
      <div class="row">
        <div class="col-7">
          Total Amount
        </div>
        <div class="col-5">
          Rs {{this.data.bookingData?.paymentDetail.totalAmount}}
        </div>
      </div>
      <div class="row" *ngIf="data.bookingData?.paymentDetail?.agentCommission">
        <div class="col-7">
          Agent Commission
          {{ data.bookingData?.paymentDetail.agentCommissionDetail?.commissionType
            ? (data.bookingData?.paymentDetail.agentCommissionDetail.commissionType == 'FLAT'
                ? '(FLAT)'
                : '(' + data.bookingData?.paymentDetail.agentCommissionDetail.commissionValue + '%)')
            : ''
          }}
        </div>
        <div class="col-5">
          Rs {{this.data.bookingData?.paymentDetail.agentCommission}}
        </div>
      </div>

      <div class="row"
           *ngIf="tokenDetail.role!='AGENT'">
        <div class="col-7">
          Discount
          {{ data.bookingData?.paymentDetail.discountDetail?.discountType
            ? (data.bookingData?.paymentDetail.discountDetail.discountType == 'FLAT'
                ? '(FLAT)'
                : '(' + data.bookingData?.paymentDetail.discountDetail.discountValue + '%)')
            : ''
          }}

        </div>
        <div class="col-5">
          Rs {{this.data.bookingData?.paymentDetail.discount ? this.data.bookingData?.paymentDetail.discount : 0 }}
        </div>
      </div>
      <hr style="margin-top: 1px;margin-bottom: 1px">
      <div class="row">
        <div class="col-7">
          Net Amount
        </div>
        <div  [ngClass]="{'col-5': true,'unpaidAmount' : this.data.bookingData?.paymentDetail.paymentStatus ==='UNPAID','paidAmount' : this.data.bookingData?.paymentDetail.paymentStatus ==='PAID'}">
          Rs {{this.data.bookingData?.paymentDetail.netAmount}}
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-7">
          Paid Amount
        </div>
        <div  class="col-5">
          Rs {{this.data.bookingData?.paymentDetail.paidAmount}}
        </div>
      </div>
      <div class="row" *ngIf="this.data.bookingData?.paymentDetail?.refundAmount">
        <div class="col-7">
          Refunded Amount
        </div>
        <div  class="col-5">
          Rs {{this.data.bookingData?.paymentDetail?.refundAmount}}
        </div>
      </div>
    </nz-card>
  </div>
</div>
<div *nzModalFooter>

  <ng-container>
    <button nz-button nzType="default"
            (click)="destroyModal()">Close</button>
    <button *ngIf="(tokenDetail.role =='ADMIN' || tokenDetail.role == 'ACCOUNT') && data?.bookingData.paymentDetail.paymentStatus =='UNPAID'" class="white-bg" nz-button nzType="primary" nzGhost
            (click)="payDetail(data?.bookingData?.id)">
      Update Payment
    </button>
    <button *ngIf="tokenDetail.role !='AGENT' && data?.bookingData.canCancel" nz-button nzType="default" nzDanger
            (click)="cancelBooking('single', data?.bookingData?.id)">Cancel</button>
    <button *ngIf="(tokenDetail.role =='ADMIN' || tokenDetail.role == 'ACCOUNT') && data?.bookingData.canRefund" nz-button nzType="default" nzDanger
            (click)="refundDetail(data?.bookingData?.id)">Refund</button>
    <!-- <button nz-button nzType="primary">Transfer Schedule</button> -->
  </ng-container>
</div>