import { SideNavInterface } from '../../interfaces/side-nav.type';
export const ROUTES: SideNavInterface[] = [
    {
        path: '/dashboard/home',
        title: 'Dashboard',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'dashboard',
        role: ['ADMIN', 'USER', 'ACCOUNT', 'AGENT'],
        submenu: []
    },
    {
        path: '/agent/credit-report',
        title: 'Agent Credit Report',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'solution',
        role: ['ADMIN', 'USER', 'ACCOUNT', 'AGENT'],
        submenu: [ ]
    },
    {
        path: '/agent/list',
        title: 'Agent Registration',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'shop',
        role: ['ADMIN', 'ACCOUNT', 'USER' ],
        submenu: [ ]
    },
    {
        path: '/user/list',
        title: 'User Registration',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user',
        role: ['ADMIN'],
        submenu: [ ]
    },
    {
        path: '/booking',
        title: 'Booking',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'calendar',
        role: ['ADMIN', 'ACCOUNT', 'USER', 'AGENT'],
        submenu: [ ]
    },
    {
        path: '/schedule/detail',
        title: 'Schedule',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'schedule',
        role: ['ADMIN', 'USER', 'AGENT'],
        submenu: [ ]
    },
    {
        path: '',
        title: 'Configuration',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'setting',
        role: ['ADMIN'],
        submenu: [
            {
                path: '/default-commission/view',
                title: 'Default Commission',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'setting',
                role: ['ADMIN'],
                submenu: [ ]
            },
            {
                path: '/default-agent-credit/view',
                title: 'Default Agent Credit',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'setting',
                role: ['ADMIN'],
                submenu: [ ]
            },
            {
                path: '/category/list',
                title: 'Balloon',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'setting',
                role: ['ADMIN'],
                submenu: [ ]
            },
            {
                path: '/pricing/list',
                title: 'Pricing',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'setting',
                role: ['ADMIN'],
                submenu: [ ]
            },
            {
                path: '/schedule/list',
                title: 'Schedule',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'setting',
                role: ['ADMIN'],
                submenu: [ ]
            },
            {
                path: '/service-halt/list',
                title: 'Service Halt',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'setting',
                role: ['ADMIN'],
                submenu: [ ]
            },
            {
                path: '/discount/list',
                title: 'Discount',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'setting',
                role: ['ADMIN'],
                submenu: [ ]
            },
         ]
    },
    {
        path: '',
        title: 'Report',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'audit',
        role: ['ADMIN', 'USER', 'ACCOUNT'],
        submenu: [
            {
                path: '/report/flightReport',
                title: 'Flight Report',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'audit',
                role: ['ADMIN', 'USER', 'ACCOUNT'],
                submenu: [ ]
            },
            {
                path: '/report/flightDetailReport',
                title: 'Flight Detail Report',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'audit',
                role: ['ADMIN', 'USER', 'ACCOUNT'],
                submenu: [ ]
            },
            {
                path: '/report/cancellationReport',
                title: 'Cancellation Report',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'audit',
                role: ['ADMIN', 'USER', 'ACCOUNT'],
                submenu: [ ]
            },
            {
                path: '/report/agentBookingReport',
                title: 'Agent Booking Report',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'audit',
                role: ['ADMIN', 'USER', 'ACCOUNT'],
                submenu: [ ]
            }
        ]
    }
];
