import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GenericService } from 'src/app/@Core/services/generic.service';
import { configApiUrl } from 'src/app/app-const';

@Injectable({
  providedIn: 'root',
})
export class BookingService extends GenericService<any> {
  constructor(http: HttpClient, router: Router) {
    super(http, configApiUrl.BOOKING_API, router);
  }

  private currentListSubject = new BehaviorSubject<string | null>(null);
  currentList$ = this.currentListSubject.asObservable();

  setCurrentList(obj: string) {
    this.currentListSubject.next(obj);
  }

  getBookingCalendar(params: any) {
    return this.http.post(this.baseUrl + '/calender', params)
  }

  getBookingCalendarDetail(params: any) {
    return this.http.post(this.baseUrl + '/calender/detail', params)
  }

  getPriceByCountry(country, serviceCode) {
    return this.http.get(this.baseUrl + '/price/' + country + '/' + serviceCode)
  }

  getScheduleDetailList(params: any) {
    return this.http.post(this.baseUrl + '/bookingInfo', params)
  }

  getBookingDetail(id: any) {
    return this.http.get(this.baseUrl + '/bookingDetail/' + id)
  }

  cancelBooking(params: any, id: any) {
    return this.http.put(this.baseUrl + '/cancelBooking/' + id, params)
  }

  cancelBookingDetail(params: any, id: any) {
    return this.http.put(this.baseUrl + '/cancelBookingDetail/' + id, params)
  }

  getRefundDetail(id: any) {
    return this.http.get(this.baseUrl + '/refundDetail/' + id)
  }

  getRefund(id: any) {
    return this.http.put(this.baseUrl + '/refund/' + id, {})
  }

  getPaymentDetail(id: any) {
    return this.http.get(this.baseUrl + '/paymentDetail/' + id)
  }

  getMarkAsPaid(id: any) {
    return this.http.put(this.baseUrl + '/markAsPaid/' + id, {})
  }

  getUpcomingFlight(){
    return this.http.get(this.baseUrl + '/upcomingFlight', {observe: 'response'}).pipe(
      map((res: any) => {
          this.setUpdatedHeader(res);
          return res.body;
      }),
      catchError((error) => this.handleError(error))
  );
  }
  getTodaysFlight(){
    return this.http.get(this.baseUrl + '/todaysFlight', {observe: 'response'}).pipe(
      map((res: any) => {
          this.setUpdatedHeader(res);
          return res.body;
      }),
      catchError((error) => this.handleError(error))
  );
  }

}