 <form #validationForm="ngForm" class="main">
    <div class="top">
        <h2>Flight Details</h2>

        <div class="detail">
            <div class="date">
                Date: <span class="detail-val"
                    style="text-decoration: underline"> {{model.bookingDate |
                    date:'yyyy/MM/dd' }} </span>
            </div>
            <div class="date">
                Flight Type: <span class="detail-val"
                    style="text-decoration: underline;">
                    {{data.categoryName}} - {{data.scheduleName}} -
                    {{data.time.slice(0,5)}}
                </span>
            </div>
        </div>
    </div>

    <div class="customer-detail">
        <h2>Customer Details</h2>
        <div class="detail">
            <div class="date">
                <span class="label">Full Name:</span>
                <span class="detail-val">
                    <input nz-input  placeholder="Full Name" type="text"
                        name="fullname" appTrim #fullname="ngModel"
                        [(ngModel)]="model.customerDetail.fullName"
                        [readonly]="data?.bookingData"
                        required />
                    <div class="error-text"
                        [hidden]="!(fullname.invalid && ( fullname.touched))">
                        Name is required
                    </div>
                </span>
            </div>
            <div class="date">
                <span class="label">Contact Number:</span>
                <span class="detail-val">
                    <input nz-input placeholder="Contact Number" type="text"
                        name="Contact" #Contact="ngModel" minlength="10" maxlength="10"
                        mask="0000000000" 
                        [(ngModel)]="model.customerDetail.contactNumber"
                        [readonly]="data?.bookingData"
                        required />
                    <div class="error-text" *ngIf="Contact.errors?.required && ( Contact.touched)">
                        Contact Number is required.
                    </div>
                    <!-- Min length error message -->
                    <div class="error-text" *ngIf="Contact.errors?.minlength && ( Contact.touched)">
                        Contact Number must be at least 10 digits long.
                    </div>
                </span>
            </div>

            <div class="date">
                <span class="label">Email:</span>
                <span class="detail-val">
                    <input nz-input placeholder="Email" type="text"
                        name="Email" #Email="ngModel" appTrim
                        [(ngModel)]="model.customerDetail.email"
                        [readonly]="data?.bookingData"
                        [pattern]="'^\\w+([-+.\']\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*$'"
                        required />

                   <div class="error-text" *ngIf="Email.errors?.required &&  Email.touched">
                      Email is required.
                    </div>

                    <div class="error-text" *ngIf="Email.errors?.pattern &&  Email.touched">
                      Please enter a valid email address.
                    </div>
                </span>
            </div>

            <div class="date">
                <span class="label">Weight:</span>
                <span class="detail-val">
                    <input nz-input placeholder="Weight" type="number"
                        name="Weight" #Weight="ngModel" appTrim appNumberInput
                        [(ngModel)]="model.customerDetail.weight"
                        [readonly]="data?.bookingData"
                        required />
                    <div class="error-text"
                        [hidden]="!(Weight.invalid && Weight.touched)">
                        Weight is required
                    </div>
                </span>
            </div>
            <div class="date">
                <span class="label">Pick up Location:</span>
                <span class="detail-val">
                    <input nz-input placeholder="Pick" type="text"
                        name="Pick" #Pick="ngModel"
                        [(ngModel)]="model.customerDetail.pickUpLocation"
                        [readonly]="data?.bookingData"
                        required />
                    <div class="error-text"
                        [hidden]="!(Pick.invalid &&  Pick.touched)">
                        Pick up Location is required
                    </div>
                </span>
            </div>
            <div class="date">
                <span class="label">DOB:</span>
                <span class="detail-val">
                    <nz-form-item>
                        <nz-form-control>
                            <nz-date-picker class="full-width"
                            [nzDisabledDate]="disabledDate"
                                [(ngModel)]="model.customerDetail.dateOfBirth"
                                [nzDefaultPickerValue]="minDate"
                                name="Dob" #Dob="ngModel"
                                [nzFormat]="'yyyy-MM-dd'"
                                [disabled]="data?.bookingData"
                                required></nz-date-picker>
                        </nz-form-control>
                    </nz-form-item>
                    <div class="error-text" style="margin-top: -20px;"
                        [hidden]="!(Dob.invalid && Dob.touched)">
                        DOB is required
                    </div>
                </span>
            </div>
        </div>

        <div class="image-div detail">
            <div class="image-content">

                <div class="date">
                    <span class="label">Nationality:</span>
                    <span class="detail-val">
                        <nz-select nzShowSearch nzAllowClear
                            name="Nationality" #Nationality="ngModel"
                            [(ngModel)]="model.customerDetail.country"
                            required
                            [disabled]="data?.bookingData"
                            (ngModelChange)="getPriceByCountry()"
                            nzPlaceHolder="Select...">
                            <nz-option *ngFor="let cont of countrylist"
                                [nzLabel]="cont.name" [nzValue]="cont.name">
                            </nz-option>
                        </nz-select>
                        <div class="error-text"
                            [hidden]="!(Nationality.invalid &&  Nationality.touched)">
                            Nationality is required
                        </div>
                    </span>
                </div>
                <div class="date">
                    <span class="label">Gender:</span>
                    <span class="detail-val">
                        <nz-select nzShowSearch nzAllowClear
                            name="Gender" #Gender="ngModel"
                            [(ngModel)]="model.customerDetail.gender"
                            required
                            [disabled]="data?.bookingData"
                            nzPlaceHolder="Select...">
                            <nz-option nzLabel="MALE"
                                nzValue="MALE"></nz-option>
                            <nz-option nzLabel="FEMALE"
                                nzValue="FEMALE"></nz-option>
                            <nz-option nzLabel="OTHER"
                                nzValue="OTHER"></nz-option>
                        </nz-select>
                        <div class="error-text"
                            [hidden]="!(Gender.invalid &&  Gender.touched)">
                            Gender is required
                        </div>
                    </span>
                </div>
            </div>
            <div class="image-content">
                <div *ngIf="image !=='' "
                    class="form-group mt-2 mb-2 text-center">
                    <img class="event-img" [src]="image" alt="event-image">
                </div>
                <div class="dropzone" draggable="true">

                    <!-- <h3>Drag your picture here</h3> -->
                    <div class="separator"></div>
                    <div style="text-align: center;">
                        <input type="file"
                            accept="image/png, image/jpeg, iamge/jpg"
                            id="fileDropRef"
                            (change)="onFileChange($event)"
                            style="display:none;" />
                        <label *ngIf="!data?.bookingData" for="fileDropRef">
                            <span
                                class="icon-upload-picture">
                                {{ image !='' ? 'Change Image' :
                                'Click to Upload Document' }} </span> </label>
                    </div>
                    <p>Image size should be less than 500KB</p>
                    <div class="error-text"
                        *ngIf="(submitClicked && this.image =='') ||  (data?.bookingData && this.image =='')">
                        Image is required
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="customer-detail" *ngIf="model.customerDetail.country">
        <h2>Payment Details</h2>

        <div class="row">
            <div class="col-sm-6">
                <div class="mt-2"
                    *ngIf="model.paymentDetail.exchangeRateDetail.currency =='DOLLAR'">
                    <span class="label"> Dollar Amount:</span>
                    <span class="detail-val" style="position: relative;">
                        <div
                            style="position: absolute; bottom: 0; left: -90px; z-index: 9;">$</div>
                        <input style="padding-left: 35px;" nz-input
                            placeholder="Total Amount" type="number"
                            readonly
                            name="amount" #amount="ngModel"
                            [(ngModel)]="model.paymentDetail.exchangeRateDetail.amount" />
                        <!-- <div class="error-text"
                        [hidden]="!(totalAmount.invalid && (totalAmount.dirty || totalAmount.touched))">
                        Total Amount is required
                    </div> -->

                    </span>
                </div>

                <div class="mt-2"
                    *ngIf="model.paymentDetail.exchangeRateDetail.currency =='DOLLAR'">
                    <span class="label">Conv. Rate:</span>
                    <span class="detail-val" style="position: relative;">
                        <div
                            style="position: absolute; bottom: 0; left: -67px; z-index: 9;">Rs</div>
                        <input style="padding-left: 35px;" nz-input
                            placeholder="Conv. Rate" type="number"
                            readonly
                            name="rate" #rate="ngModel"
                            [(ngModel)]="model.paymentDetail.exchangeRateDetail.rate" />
                    </span>
                </div>

                <div class="mt-2">
                    <span class="label">Total Amount:</span>
                    <span class="detail-val" style="position: relative;">
                        <div
                            style="position: absolute; bottom: 0; left: -85px; z-index: 9;">Rs</div>
                        <input style="padding-left: 35px;" nz-input
                            placeholder="Total Amount" type="number"
                            name="totalAmount" #totalAmount="ngModel"
                            [(ngModel)]="model.paymentDetail.totalAmount"
                            readonly />
                    </span>
                </div>

                <div class="mt-2" *ngIf="tokenDetail.role!='AGENT' && data?.bookingData && data?.bookingData?.agent">
                    <span class="label">Agent </span>
                    <span class="detail-val" style="position: relative;">
                        <input  nz-input [value]="data?.bookingData?.agent?.name" readonly/>
                    </span>
                </div>
                <div class="mt-2" *ngIf="tokenDetail.role=='AGENT' || data?.bookingData?.agent">
                    <span class="label">Agent Commission:</span>
                    <span class="detail-val" style="position: relative;">
                        <div
                            style="position: absolute;bottom: 0;top: 30px;left: -124px;z-index: 9;">Rs</div>
                        <input style="padding-left: 35px;" nz-input
                            placeholder="Agent Commission" type="number"
                            name="commissionValue" #commissionValue="ngModel"
                            [(ngModel)]="model.paymentDetail.agentCommission"
                            readonly
                            required />
                        <div class="error-text"
                            [hidden]="!(commissionValue.invalid && (commissionValue.dirty || commissionValue.touched))">
                            Commission Amount is required
                        </div>
                    </span>
                </div>

                <div class="mt-2" *ngIf="tokenDetail.role!='AGENT' && data?.bookingData && model.paymentDetail.discountDetail?.discountType">
                    <span class="label">Discount Type </span>
                    <span class="detail-val" style="position: relative;">
                        <input  nz-input [value]="model.paymentDetail.discountDetail?.name" readonly/>
                    </span>
                </div>
                <div class="mt-2"
                    *ngIf="tokenDetail.role!='AGENT' && !data?.bookingData">
                    <span class="label">Discount Type:</span>
                    <span class="detail-val">
                        <span class="detail-val">
                            <nz-select nzShowSearch nzAllowClear
                                (ngModelChange)="discountChange($event)"
                                name="discountType"
                                [(ngModel)]="model.paymentDetail.discountDetail"
                                [disabled]="data?.bookingData"
                                nzPlaceHolder="Select...">
                                <nz-option *ngFor="let dis of discountList"
                                    nzLabel={{dis.name}}
                                    [nzValue]="dis"></nz-option>
                            </nz-select>
                        </span>
                    </span>
                </div>

                <div class="mt-2"
                     *ngIf="tokenDetail.role!='AGENT' && model.paymentDetail.discountDetail?.discountType">
                    <span class="label">Discount:</span>
                    <span class="detail-val" style="position: relative;">
                        <div
                            style="position: absolute; bottom: 0; left: -55px; z-index: 9;">Rs</div>
                        <input style="padding-left: 35px;" nz-input
                               placeholder="Discount" type="number"
                               name="discountValue" readonly
                               [(ngModel)]="model.paymentDetail.discount" />
                    </span>
                </div>

                <div class="mt-2"
                    *ngIf="(tokenDetail.role=='ADMIN' || tokenDetail.role=='USER')  && model.paymentDetail.discountDetail?.discountType">
                    <span class="label">Discount Authorized by:</span>
                    <span class="detail-val">
                        <nz-select nzShowSearch nzAllowClear
                            name="discountAuthorizedBy"
                            [(ngModel)]="model.paymentDetail.discountAuthorizedBy"
                            [disabled]="data?.bookingData"
                            nzPlaceHolder="Select...">
                            <nz-option *ngFor="let user of userList"
                                [nzLabel]="user.firstName +' '+ user.lastName"
                                [nzValue]="user.id"></nz-option>
                        </nz-select>
                    </span>
                </div>

                <div class="mt-2">
                    <span class="label">Net Amount:</span>
                    <span class="detail-val" style="position: relative;">
                        <div
                            style="position: absolute; bottom: 0; left: -75px; z-index: 9;">Rs</div>
                        <input style="padding-left: 35px;" nz-input
                            placeholder="Net Amount" type="number"
                            name="netAmount" #netAmount="ngModel"
                            [(ngModel)]="model.paymentDetail.netAmount"
                            readonly />
                    </span>
                </div>

            </div>
            <div class="col-sm-6">

                <div class="mt-2" *ngIf="tokenDetail.role!='AGENT' && data?.bookingData ">
                    <span class="label">Payment Status </span>
                    <span class="detail-val" style="position: relative;">
                        <input  nz-input [value]="data?.bookingData.paymentDetail?.paymentStatus" readonly/>
                    </span>
                </div>
                <div class="mt-2"
                    *ngIf="(tokenDetail.role=='ADMIN' || tokenDetail.role=='USER') && !data?.bookingData">
                    <span class="label">Payment Status:</span>
                    <span class="detail-val">
                        <nz-select nzShowSearch nzAllowClear
                            #paymentStatus="ngModel" name="paymentStatus"
                            [(ngModel)]="model.paymentDetail.paymentStatus"
                            (ngModelChange)="paymentStatusChange($event)"
                            required
                            [disabled]="data?.bookingData"
                            nzPlaceHolder="Select...">
                            <nz-option nzLabel="PAID"
                                nzValue="PAID"></nz-option>
                            <nz-option nzLabel="UNPAID"
                                nzValue="UNPAID"></nz-option>
                        </nz-select>
                        <div class="error-text"
                            [hidden]="!(paymentStatus.invalid && (paymentStatus.dirty || paymentStatus.touched))">
                            Payment Status is required
                        </div>
                    </span>
                </div>
                <div class="mt-2"
                    *ngIf="tokenDetail.role=='ADMIN' || tokenDetail.role=='USER'">
                    <span class="label">Paid Amount:</span>
                    <span class="detail-val" style="position: relative;">
                        <div
                            style="position: absolute;bottom: 0;top: 30px;left: -82px;z-index: 9;">Rs</div>
                        <input style="padding-left: 35px;" nz-input
                            placeholder="Paid Amount" type="number"
                            name="paidAmount" #paidAmount="ngModel"
                            [(ngModel)]="model.paymentDetail.paidAmount"
                            [readonly]="true"
                            />
                        <div class="error-text"
                            [hidden]="!(paidAmount.invalid && (paidAmount.dirty || paidAmount.touched))">
                            Paid Amount is required
                        </div>
                    </span>
                </div>

                <div class="mt-2"
                    *ngIf="(tokenDetail.role=='ADMIN' || tokenDetail.role=='USER')  && model.paymentDetail.paymentStatus == 'UNPAID'">
                    <span class="label">Payment Authorized by:</span>
                    <span class="detail-val">
                        <nz-select nzShowSearch nzAllowClear
                            #paymentAuthorizedBy="ngModel"
                            name="paymentAuthorizedBy"
                            [(ngModel)]="model.paymentDetail.paymentAuthorizedBy"
                            required
                            [disabled]="data?.bookingData"
                            nzPlaceHolder="Select...">
                            <nz-option *ngFor="let user of userList"
                                [nzLabel]="user.firstName +' '+ user.lastName"
                                [nzValue]="user.id"></nz-option>
                        </nz-select>
                        <div class="error-text"
                            [hidden]="!(paymentAuthorizedBy.invalid && (paymentAuthorizedBy.dirty || paymentAuthorizedBy.touched))">
                            Payment Authorized By is required
                        </div>
                    </span>
                </div>

                <div class="mt-2" *ngIf="tokenDetail.role!='AGENT' && data?.bookingData && data?.bookingData.paymentDetail?.refundAmount">
                    <span class="label">Refunded Amount </span>
                    <span class="detail-val" style="position: relative;">
                        <input  nz-input [value]="data?.bookingData.paymentDetail?.refundAmount" readonly/>
                    </span>
                </div>

            </div>
        </div>

    </div>

</form>
<div *nzModalFooter>

    <ng-container *ngIf="!data?.bookingData">
        <button nz-button nzType="default"
            (click)="destroyModal()">Cancel</button>
        <button nz-button nzType="primary" (click)="submit()">Save</button>
    </ng-container>

    <ng-container *ngIf="data?.bookingData && tokenDetail.role !='AGENT'">
        <button *ngIf="data?.bookingData.paymentDetail.paymentStatus =='UNPAID'" class="white-bg" nz-button nzType="primary" nzGhost
        (click)="payDetail(data?.bookingData?.id)">
            Update Payment
        </button>
        <button *ngIf="data?.bookingData.canCancel" nz-button nzType="default" nzDanger
            (click)="cancelBooking('single', data?.bookingData?.id)">Cancel</button>
        <button *ngIf="data?.bookingData.canRefund" nz-button nzType="default" nzDanger
        (click)="refundDetail(data?.bookingData?.id)">Refund</button>
        <!-- <button nz-button nzType="primary">Transfer Schedule</button> -->
    </ng-container>
</div>
